<template>

<vue-bootstrap-typeahead
  inputClass="form-control form-control-solid"
  v-model="getValue"
  ref="typeahead"
  :maxMatches="20"
  :placeholder="placeholder"
  :minMatchingChars="1"
  :data="autocompleteItems"
/>

</template>

<script>
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import Vue from 'vue'

export default {
  name: 'InputAutocomplete',
  components: { VueBootstrapTypeahead },
  props: {
    value: null,
    fetchOptionsUrl: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      autocompleteItems: [],
    }
  },
  computed: {
    getValue: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
  },
  mounted() {
    this.$refs.typeahead.inputValue = this.value
    Vue.prototype.$http.get(this.fetchOptionsUrl)
      .then(({ data }) => {
        this.autocompleteItems = data.payload
      })
  },
}
</script>

<style scoped>
</style>
