<template>
<b-row>
  <b-col cols="8" class="d-flex align-items-center">
    <h5 class="text-dark font-weight-bold m-0">{{ !resetItems ? orderItem.name : $t('ORDER.SETUP.OTHER_SERVICES_AND_PRODUCTS') }}</h5>
  </b-col>
  <b-col cols="4" class="d-flex align-items-center justify-content-end">
    <span v-if="!resetItems" @click="$emit('onDeleteItem', orderItem)" class="svg-icon svg-icon-danger mr-3 pb-1 cursor-pointer">
      <inline-svg src="/media/svg/icons/General/Trash.svg" />
    </span>
    <span v-if="!resetItems" @click="$emit('onEditItem', orderItem)" class="svg-icon svg-icon-info cursor-pointer">
      <inline-svg src="/media/svg/icons/Design/Edit.svg" />
    </span>
    <b-dropdown
      size="sm"
      variant="link"
      toggle-class="text-decoration-none p-0"
      no-caret
      right
      no-flip
    >
      <template #button-content>
        <div class="btn btn-icon btn-lg btn-dropdown p-0">
          <span class="svg-icon svg-icon-success">
            <inline-svg src="/media/svg/icons/Files/File-plus.svg" />
          </span>
        </div>
      </template>
      <b-dropdown-item @click="isShowServiceForm = true">{{ $t('ORDER.SETUP.ADD_SERVICE') }}</b-dropdown-item>
      <b-dropdown-item @click="isShowProductForm = true">{{ $t('ORDER.SETUP.ADD_PRODUCT') }}</b-dropdown-item>
    </b-dropdown>
  </b-col>
  <b-col cols="12">
    <Table per-page="0" :fields="getFields" :items="getItems" :busy.sync="loading">
      <template #cell(errorField)="data">
        <div style="min-width: 24px">
          <template
            v-if="showWarning(data.item.uuid)"
          >
            <span
              :id="`popover-${data.item.uuid}`" class="svg-icon svg-icon-lg svg-icon-warning"
            >
              <inline-svg src="/media/svg/icons/Code/Warning-2.svg" />
            </span>
            <b-popover
              :target="`popover-${data.item.uuid}`"
              triggers="hover"
              variant="warning"
              placement="auto"
            >
              <template #title>
                <div class="text-dark-75 font-weight-bolder font-size-lg">{{ $t('BASE.ERRORS') }}:</div>
              </template>
              <div v-for="(itemsError, key) in getErrorList(data.item.uuid)" :key="data.item.uuid + '_' + key">
                <div class="my-2">
                  <div class="text-dark-75 font-weight-bolder font-size-sm">{{ $t(`VALIDATION.ORDER.${key}`) }}:</div>
                  <div v-for="(err, index) in itemsError" :key="index">
                    <div class="text-dark-75 font-size-sm mb-0">{{ err }}</div>
                  </div>
                </div>
              </div>
            </b-popover>
          </template>

          <template
            v-else-if="orderWarning(data.item)"
          >
            <span
              :id="`warning-popover-${data.item.uuid}`" class="svg-icon svg-icon-lg svg-icon-warning"
            >
              <inline-svg src="/media/svg/icons/Code/Warning-2.svg" />
            </span>
            <b-popover
              :target="`warning-popover-${data.item.uuid}`"
              triggers="hover"
              variant="warning"
              placement="auto"
            >
              <div class="text-dark-75 font-size-sm mb-0">{{ orderWarning(data.item) }}</div>
            </b-popover>
          </template>
        </div>
      </template>
      <template #cell(name)="data">
        <div style="min-width: 180px">
          <div class="text-dark-75 font-weight-bolder font-size-lg mb-0">{{ data.item.name }}</div>
        </div>
      </template>
      <template #cell(cost)="data">
        <div style="min-width: 50px">
          <span class="text-muted font-weight-bold">
            {{ data.item.unitCostWithoutDiscount | centsToDollars }}
          </span>
        </div>
      </template>
      <template #cell(totalCost)="data">
        <div style="width: 100px" v-if="!data.item.customPrice">
          <span class="text-muted font-weight-bold" @click="onCustomPriceBegin(data.item)">
            {{ data.item.costWithDiscount | centsToDollars }}
          </span>
        </div>
        <div style="width: 100px" v-else>
          <InputCurrency v-model="data.item.costWithoutDiscount"
                         @input="onCustomPriceSet($event, data.item)"
                         debounce="800"
                         :placeholder="$t('ORDER.SETUP.TOTAL')"
          />
        </div>
      </template>
      <template #cell(quantity)="data">
        <div style="min-width: 50px">
          <InputForm
            id="input-1"
            size="sm"
            v-model="data.item.quantity"
            :placeholder="$t('PLACEHOLDER.QUANTITY')"
            debounce="500"
            @input="onEditQuantity(data.item, 'quantity')"
            aria-describedby="input-5-feedback"
          />
        </div>
      </template>
      <template #cell(discount)="data">
        <div style="min-width: 50px">
          <div class="d-flex">
            <div v-for="discount in data.item.discounts"
                 :key="discount.uuid"
                 :class="discount.type === 'absolute' ? 'label-info' : 'label-warning'"
                 class="label label-xl label-inline pr-1 mr-3 d-flex align-items-center position-relative"
            >
              <span
                @click="showDiscountForm(data.item, discount)"
                style="line-height: 12px;"
                class="d-flex align-items-center pr-2 cursor-pointer"
              >
                <template v-if="discount.type === 'absolute'">
                  {{ discount.value | centsToDollars }}
                </template>
                <template v-else>
                  {{ discount.value }}
                </template>
                <span v-if="discount.type === 'percent'" class="svg-icon svg-icon-light svg-icon-sm">
                  <inline-svg src="/media/svg/icons/Shopping/Sale1.svg" />
                </span>
                <span v-else class="svg-icon svg-icon-light svg-icon-sm">
                  <inline-svg src="/media/svg/icons/Shopping/Price1.svg" />
                </span>
              </span>
              <span
                @click="onRemoveDiscount(data.item, discount)"
                style="cursor:pointer; top: -8px; right: -8px;"
                class="bg-secondary rounded svg-icon svg-icon-secondary svg-icon-sm position-absolute"
              >
                <inline-svg src="/media/svg/icons/Navigation/Close.svg" />
              </span>
            </div>
          </div>
        </div>
      </template>
      <template #cell(actions)="data">
        <div style="min-width: 110px">
          <b-button v-if="!(data.item.discounts && data.item.discounts.length > 4)"
                    class="btn btn-icon btn-light-info btn-sm mr-3" @click="showDiscountForm(data.item)"
          >
            <span class="svg-icon svg-icon-md btn-light-info">
              <inline-svg src="/media/svg/icons/Shopping/Sale2.svg" />
            </span>
          </b-button>
          <div class="btn btn-icon btn-light-danger btn-sm mr-3" @click="onDelete(data.item)">
            <span class="svg-icon svg-icon-md btn-light-info">
              <inline-svg src="/media/svg/icons/General/Trash.svg" />
            </span>
          </div>
          <div class="btn btn-icon btn-light-primary btn-sm" @click="onDuplicate(data.item)">
            <span class="svg-icon svg-icon-md btn-light-info">
              <inline-svg src="/media/svg/icons/General/Duplicate.svg" />
            </span>
          </div>
        </div>
      </template>
      <template #custom-foot>
        <b-tr>
          <b-td v-for="field in getFields" :key="field.key">
            <template v-if="field.key === 'name'">
              <span class="font-weight-bold">
                {{ $t('ORDER.SETUP.TOTAL') }}
              </span>
            </template>
            <template v-else-if="field.key === 'cost'">
              <span class="text-muted font-weight-bold">
                {{ getItems.reduce((cur, el) => cur += el.unitCostWithoutDiscount ? el.unitCostWithoutDiscount : 0, 0) | centsToDollars }}
              </span>
            </template>
            <template v-else-if="field.key === 'totalCost'">
              <span class="text-muted font-weight-bold">
                {{ getItems.reduce((cur, el) => cur += el.costWithDiscount ? el.costWithDiscount : 0, 0) | centsToDollars }}
              </span>
            </template>
          </b-td>
        </b-tr>
      </template>
    </Table>
  </b-col>
  <quick-panel v-model="isShowProductForm">
    <template #title>{{ $t('ORDER.SETUP.PRODUCTS_LIST') }}</template>
    <template #body>
      <OrderProductForm @onSubmit="addProduct" />
    </template>
  </quick-panel>
  <quick-panel v-model="isShowServiceForm">
    <template #title>{{ $t('ORDER.SETUP.SERVICES_LIST') }}</template>
    <template #body>
      <OrderServiceForm @onSubmit="addService" />
    </template>
  </quick-panel>
  <OrderDiscountForm v-if="isShow && isQuickPanel && getPanelName === 'OrderDiscountForm'" :itemEdit="itemEditDiscount" @onSubmit="addDiscount" />
</b-row>
</template>

<script>
import RepoDiscount from '@/core/repository/company/discountRepository'
import RepoOrderLine from '@/core/repository/company/orderLineRepository'
import ModalConfirm from '@/components/modals/ModalConfirm'
import { FETCH_ORDER } from '@/core/services/store/order.module'
import { mapGetters, mapState } from 'vuex'
import InputForm from '../../../forms-items/input'
import InputCurrency from '../../../forms-items/inputCurrency'
import Table from '../../../UI/Table'

const OrderProductForm = () => import('./OrderProductForm')
const OrderServiceForm = () => import('./OrderServiceForm')
const OrderDiscountForm = () => import('./OrderDiscountForm')
export default {
  name: 'ItemOrder',
  components: {
    OrderServiceForm,
    InputForm,
    InputCurrency,
    Table,
    OrderProductForm,
    OrderDiscountForm,
  },
  props: {
    value: null,
    onValid: {
      type: Object,
      default: () => ({}),
    },
    orderItem: {
      type: Object,
      default: () => ({}),
    },
    resetItems: {
      type: Boolean,
    },
  },
  data() {
    return {
      isShowProductForm: false,
      isShowServiceForm: false,
      isShow: false,
      visibleRows: [],
      loading: false,
      item: {
        quantity: '',
        order: '',
        product: '',
        customPrice: false,
        discounts: [],
        service: '',
        comment: '',
      },
      filedError: {
        key: 'errorField',
        tdClass: 'w-20 px-0',
        label: '',
        sortable: false,
      },
      fields: [
        {
          key: 'name',
          label: this.$t('ORDER.SETUP.NAME'),
          sortable: false,
        },
        // {
        //   key: 'cost',
        //   label: this.$t('ORDER.SETUP.COST'),
        //   sortable: false
        // },
        // {
        //   key: 'quantity',
        //   label: this.$t('ORDER.SETUP.QUANTITY'),
        //   sortable: false
        // },
        {
          key: 'discount',
          label: this.$t('ORDER.SETUP.DISCOUNTS'),
          sortable: false,
        },
        {
          key: 'totalCost',
          label: this.$t('ORDER.SETUP.TOTAL_COST'),
          sortable: false,
        },
        {
          key: 'actions',
          label: this.$t('BASE.ACTIONS'),
          class: 'text-right',
          sortable: false,
        },
      ],
      errors: [],
      orderLineForDiscount: null,
      itemEditDiscount: null,
    }
  },
  computed: {
    ...mapGetters([
      'isQuickPanel',
      'getPanelName',
    ]),
    ...mapState({
      order: (state) => state.order.item,
      warnings: (state) => state.order.orderWarnings,
    }),
    orderLineWarnings() {
      return this.warnings.orderLine || {}
    },
    showWarning() {
      return (uuid) => {
        return Object.keys(this.errors)
          .includes(uuid)
      }
    },
    getFields() {
      return [this.filedError].concat(this.fields)
    },
    getErrorList() {
      return (uuid) => {
        if (this.errors[uuid]) {
          return this.errors[uuid].errors
        }
        return []
      }
    },
    getItems() {
      if (this.orderItem && this.orderItem.uuid) {
        return this.value.filter((f) => {
          return f.items.map((m) => m.uuid)
            .includes(this.orderItem.uuid)
        })
          .map((item) => {
            item.name = item?.product?.name || item?.service?.name || item.name
            return item
          })
      }
      return this.value.filter((f) => !f.items.length)
        .map((item) => {
          item.name = item?.product?.name || item?.service?.name || item.name
          return item
        })
    },
  },
  watch: {
    getItems: {
      handler() {
        this.onValidate()
      },
      deep: true,
      immediate: true,
    },
    errors: {
      handler() {
        this.onValidate()
      },
      deep: true,
    },
  },
  methods: {
    orderWarning(orderLine) {
      const warnings = this.orderLineWarnings

      return warnings[orderLine.uuid] && warnings[orderLine.uuid].services[orderLine.service.uuid]
    },
    onValidate() {
      const obj = { ...this.onValid }
      const key = this.orderItem && this.orderItem.uuid ? this.orderItem.uuid : 'default'
      obj[key] = !this.getItems.some((item) => !Array.isArray(this.getErrorList(item.uuid)))
      this.$emit('update:onValid', obj)
    },
    onEditQuantity(item) {
      this.clearServerError(item.uuid, 'quantity')
      this.onEditItem(item)
    },
    onEditItem(item) {
      if (item.type) return
      const obj = {
        order: this.order.uuid,
        quantity: item.quantity,
        customPrice: item.customPrice,
        discounts: item.discounts ? item.discounts.map((m) => m.uuid) : [],
      }
      if (item.product) obj.product = item.product.uuid
      if (item.costWithoutDiscount != null) obj.costWithoutDiscount = item.costWithoutDiscount
      if (item.service) obj.service = item.service.uuid
      if (this.orderItem && this.orderItem.uuid) obj.items = [this.orderItem.uuid]
      RepoOrderLine.patch(item.uuid, obj)
        .then(({ data }) => {
          const items = this.value.map((m) => {
            if (m.uuid === data.payload.uuid) {
              return data.payload
            }
            return m
          })
          this.$store.dispatch(FETCH_ORDER, this.$route.query.order)
          this.$emit('input', items)
        })
        .catch((err) => {
          this.$set(this.errors, item.uuid, err.response.data)
        })
    },
    clearServerError(uuid, prop) {
      if (this.errors[uuid]) {
        if (Object.keys(this.errors[uuid].errors).length) {
          delete this.errors[uuid].errors[prop]
        }
      }
      if (this.errors[uuid] && !Object.keys(this.errors[uuid].errors).length) delete this.errors[uuid]
    },
    showDiscountForm(orderLineForDiscount, itemEditDiscount) {
      this.orderLineForDiscount = orderLineForDiscount
      this.itemEditDiscount = itemEditDiscount
      this.isShow = true
      this.$store.commit('setQuickPanelName', 'OrderDiscountForm')
      this.$store.commit('setQuickPanelTitle', this.$t('ORDER.DISCOUNT.FORM_TITLE'))
      this.$store.commit('onQuickPanelOpen')
    },
    addProduct(arr) {
      this.isShowProductForm = false
      this.onPostItem(arr, 'product')
    },
    addService(arr) {
      this.isShowServiceForm = false
      this.onPostItem(arr, 'service')
    },
    addDiscount(res) {
      if (this.itemEditDiscount) {
        this.onEditDiscount(res)
      } else {
        this.onAddDiscount(res)
      }
      this.orderLineForDiscount = null
      this.itemEditDiscount = null
      this.$store.commit('onQuickPanelClose')
      this.isShow = false
    },
    onPostItem(arr, type, props = {}) {
      arr.forEach((item, index) => {
        console.log(item)
        const obj = {
          order: this.order.uuid,
          quantity: 1,
          [type]: item.uuid,
        }
        Object.keys(props).forEach((p) => {
          obj[p] = props[p]
        })
        if (this.orderItem && this.orderItem.uuid) {
          obj.items = [this.orderItem.uuid]
          item.items = [this.orderItem]
        } else {
          item.items = []
        }
        this.loading = true
        RepoOrderLine.post(obj)
          .then(({ data }) => {
            // eslint-disable-next-line vue/no-mutating-props
            this.value.push(data.payload) // TODO: ???
            if (arr.length - 1 === index) this.$store.dispatch(FETCH_ORDER, this.$route.query.order)
          })
          .catch((err) => {
            // eslint-disable-next-line vue/no-mutating-props
            this.value.push(item) // TODO: ???
            this.$set(this.errors, item.uuid, err.response.data)
          })
          .finally(() => {
            this.loading = false
          })
      })
    },
    onRemoveDiscount(orderLine, item) {
      const index = orderLine.discounts.findIndex((f) => f.uuid === item.uuid)
      if (index >= 0) {
        RepoDiscount.delete(item.uuid)
          .then(() => {
            orderLine.discounts.splice(index, 1)
            this.onEditItem(orderLine)
          })
      }
    },
    onEditDiscount(item) {
      const index = this.orderLineForDiscount.discounts.findIndex((f) => f.uuid === item.uuid)
      if (index >= 0) {
        this.$set(this.orderLineForDiscount.discounts, index, item)
        this.onEditItem(this.orderLineForDiscount)
      }
    },
    onAddDiscount(res) {
      const index = this.value.findIndex((f) => f.uuid === this.orderLineForDiscount.uuid)
      if (index >= 0) {
        const obj = { ...this.orderLineForDiscount }
        if (!obj.discounts) {
          obj.discounts = [res]
        } else {
          obj.discounts.push(res)
        }
        this.$set(this.value, index, obj)
        this.onEditItem(this.value[index])
      }
    },
    onDelete(item) {
      this.$modal.show(ModalConfirm, {
        handlers: {
          onConfirm: () => {
            const index = this.value.findIndex((f) => f.uuid === item.uuid)
            if (index >= 0) {
              if (!this.value[index].type) {
                RepoOrderLine.delete(this.value[index].uuid)
                  .then(() => {
                    this.$store.dispatch(FETCH_ORDER, this.$route.query.order)
                  })
              }
              // eslint-disable-next-line vue/no-mutating-props
              this.value.splice(index, 1) // TODO: ???
            }
          },
        },
      }, {
        height: 'auto',
        adaptive: true,
        clickToClose: false,
      })
    },
    onDuplicate(item) {
      let serviceOrProduct = item.service
      let type = 'service'
      const props = {}
      if (item.product !== null) {
        serviceOrProduct = item.product
        type = 'product'
      }
      if (item.hasOwnProperty('customPrice') && item.customPrice !== false) {
        props.customPrice = item.customPrice
        props.costWithoutDiscount = item.costWithoutDiscount
      }
      this.onPostItem([serviceOrProduct], type, props)
    },
    onCustomPriceBegin(item) {
      item.customPrice = true
    },
    onCustomPriceSet(event, item) {
      this.onEditItem(item)
    },
  },
}
</script>

<style scoped>

</style>
