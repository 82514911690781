<template>
<div class="d-flex flex-column h-100">
  <div class="w-100 flex-fill">
    <b-form class="form" @submit.stop.prevent="onSubmit">
      <b-form-group :label="`${$t('FORM.NAME')}`" label-for="input-1">
        <InputAutocomplete
          id="input-1"
          v-model="$v.form.name.$model"
          :placeholder="$t('PLACEHOLDER.NAME')"
          trim
          fetchOptionsUrl="/company/inventory-item/popular"
          @input="clearServerError('name')"
          aria-describedby="input-1-feedback"
        />
        <b-form-invalid-feedback id="input-1-feedback" :class="{'d-block': validateState('name') === false}">
          <template v-if="serverErrors.name">{{ serverErrors.name[0] }}</template>
          <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('FORM.NAME') }) }}</template>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group :label="`${$t('FORM.DESCRIPTION')}`" label-for="input-5">
        <Textarea
          id="input-5"
          v-model="$v.form.comment.$model"
          :state="validateState('comment')"
          :placeholder="$t('PLACEHOLDER.DESCRIPTION')"
          @input="clearServerError('comment')"
          aria-describedby="input-5-feedback"
        />
        <b-form-invalid-feedback id="input-5-feedback">
          <template v-if="serverErrors.description">{{ serverErrors.comment[0] }}</template>
        </b-form-invalid-feedback>
      </b-form-group>
    </b-form>
  </div>

  <b-button class="d-flex align-items-center justify-content-center btn btn-block btn-light-success font-weight-bold btn-sm" @click="onSubmit">
    <span class="svg-icon btn-light-success">
      <inline-svg src="/media/svg/icons/Files/File-plus.svg" />
    </span>
    {{ $t('FORM.SUBMIT') }}
  </b-button>
  <div class="fake-element">
    <!--      IOS fix-->
  </div>
</div>
</template>

<script>
import { FETCH_ORDER } from '@/core/services/store/order.module'
import { mapState } from 'vuex'
import Repo from '@/core/repository/company/inventoryItemRepository'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import InputAutocomplete from '../../../forms-items/InputAutocomplete'
import serverVuelidate from '../../../../mixins/serverVuelidate'
import Textarea from '../../../forms-items/Textarea'

export default {
  name: 'ItemOrderForm',
  components: {
    Textarea,
    InputAutocomplete,
  },
  mixins: [validationMixin, serverVuelidate],
  props: {
    itemEdit: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      form: {
        uuid: '',
        name: '',
        comment: '',
      },
      validations: {
        form: {
          name: { required },
          comment: { },
        },
      },
      loading: false,
    }
  },
  computed: {
    ...mapState({
      order: (state) => state.order.item,
    }),
  },
  created() {
    if (this.itemEdit && Object.keys(this.itemEdit).length) {
      this.setItem(this.itemEdit)
    }
  },
  methods: {
    setItem(item) {
      Object.keys(this.form).forEach((propName) => {
        if (
          this.form.hasOwnProperty(propName)
            && item.hasOwnProperty(propName)
        ) {
          if (item[propName] !== null && item[propName] !== undefined) {
            this.form[propName] = item[propName]
          }
        }
      })
    },
    resetForm() {
      this.form = {
        uuid: '',
        name: '',
        comment: '',
      }
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    onSubmit() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        return
      }
      this.clearServerErrors()
      this.loading = true
      const obj = { ...this.form }
      obj.order = this.order.uuid
      const { uuid, ...rest } = obj
      if (uuid) {
        Repo.patch(uuid, rest).then(() => {
          this.resetForm()
          this.$emit('onEdit', obj)
          this.loading = false
        }).catch((err) => {
          this.loading = false
          this.seServerErrors(err.response.data)
        })
      } else {
        Repo.post(rest).then(({ data }) => {
          this.$store.dispatch(FETCH_ORDER, this.$route.query.order)
          this.$emit('onSubmit', data.payload)
          this.resetForm()
          this.loading = false
        }).catch((err) => {
          this.loading = false
          this.seServerErrors(err.response.data)
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
