<template>
<div>
  <b-container fluid class="p-0">
    <b-row>
      <b-col cols="12">
        <div class="d-flex justify-content-end align-items-center mb-5">
          <!--<h4 class="text-dark font-weight-bold">{{$t('ORDER.SETUP.ITEMS')}}</h4>-->
          <b-button :disabled="loadingItem" class="btn btn-light-success font-weight-bold btn-sm d-none d-sm-block" @click="active = !active">
            <b-spinner v-if="loadingItem" small variant="light" />
            <span class="svg-icon btn-light-success">
              <inline-svg src="/media/svg/icons/Files/File-plus.svg" />
            </span>
            {{ $t('ORDER.SETUP.ADD_ITEM') }}
          </b-button>
        </div>
        <ItemOrder
          v-for="item in orderItems"
          :key="item.uuid"
          :orderItem="item"
          :onValid.sync="onValid"
          v-model="items"
          @onEditItem="onShowEditItemForm"
          @onDeleteItem="onDeleteItem"
        />
        <b-button :disabled="loadingItem" variant="outline-success" class="font-weight-bold btn-sm d-sm-none w-100 my-3" @click="active = !active">
          <b-spinner v-if="loadingItem" small variant="light" />
          <span class="svg-icon btn-light-success">
            <inline-svg src="/media/svg/icons/Files/File-plus.svg" />
          </span>
          {{ $t('ORDER.SETUP.ADD_ITEM') }}
        </b-button>
        <ItemOrder
          v-model="items"
          resetItems
          :onValid.sync="onValid"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <h5 class="text-dark">
          {{ $t('ORDER.SETUP.COST_WITH_DISCOUNT') }}:
          <span class="font-weight-bold">
            {{ items.reduce((cur, el) => cur += el.costWithDiscount ? el.costWithDiscount : 0 ,0) | centsToDollars }}
          </span>
        </h5>
      </b-col>
      <b-col cols="12">
        <div class="text-right mt-5">
          <div class="btn btn-secondary font-weight-bold btn-sm mr-3" @click="goBack">
            {{ $t('BASE.BACK') }}
          </div>
          <b-button :disabled="loading || !isValid" type="submit" size="sm" variant="info" @click="onSubmit">
            <b-spinner v-if="loading" small variant="light" />
            {{ $t('FORM.SAVE_AND_NEXT') }}
          </b-button>
        </div>
      </b-col>
    </b-row>
  </b-container>
  <quick-panel v-model="active">
    <template #title>{{ itemEdit.uuid ? $t('ORDER.SETUP.ITEM_EDIT') : $t('ORDER.SETUP.ITEM_NEW') }}</template>
    <template #body>
      <ItemOrderForm v-if="active" :itemEdit="itemEdit" @onSubmit="onCreateItem" @onEdit="onEditItem" />
    </template>
  </quick-panel>
</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { FETCH_ORDER, SET_ORDER_VALID } from '@/core/services/store/order.module'
import RepoItem from '@/core/repository/company/inventoryItemRepository'
import ItemOrder from './UI/ItemOrder'
import ItemOrderForm from './UI/ItemOrderForm'
import ModalConfirm from '../../modals/ModalConfirm'

export default {
  name: 'SetupOrder',
  components: {
    ItemOrderForm,
    ItemOrder,
  },
  data() {
    return {
      active: false,
      isShow: false,
      loadingItem: false,
      loading: false,
      items: [],
      itemEdit: {},
      orderItems: [],
      onValid: {},
    }
  },
  computed: {
    ...mapGetters([
      'isQuickPanel',
      'getPanelName',
    ]),
    ...mapState({
      order: (state) => state.order.item,
      isValid: (state) => state.order.isValid,
    }),
  },
  watch: {
    active(val) {
      if (!val) this.itemEdit = {}
    },
    onValid: {
      handler(val) {
        this.$store.commit(SET_ORDER_VALID, Object.values(val).every((item) => !!item))
      },
      deep: true,
      immediate: true,
    },
    'order.uuid': {
      handler(val) {
        if (val) this.fetchData(this.order)
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    fetchData(data) {
      this.items = data.lines
      this.orderItems = data.items
    },
    goBack() {
      this.$router.replace({
        query: {
          ...this.$route.query,
          type: 'client',
        },
      })
    },
    onSubmit() {
      this.$router.replace({
        query: {
          ...this.$route.query,
          type: 'files',
        },
      })
    },
    onCreateItem(res) {
      this.orderItems.push(res)
      this.active = false
    },
    onDeleteItem(item) {
      this.$modal.show(ModalConfirm, {
        handlers: {
          onConfirm: () => {
            RepoItem.delete(item.uuid).then(() => {
              this.$store.dispatch(FETCH_ORDER, this.$route.query.order)
            })
          },
        },
      }, {
        height: 'auto',
        adaptive: true,
        clickToClose: false,
      })
    },
    onShowEditItemForm(item) {
      const itemCopy = JSON.parse(JSON.stringify(item))
      this.itemEdit = itemCopy
      this.active = true
    },
    onEditItem(res) {
      const index = this.orderItems.findIndex((f) => f.uuid === this.itemEdit.uuid)
      if (index >= 0) {
        this.$set(this.orderItems, index, res)
      }
      this.active = false
    },
  },
}
</script>

<style lang="scss">

</style>
